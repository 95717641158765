import React from "react";
import PropTypes from "prop-types";
import { Collapse, Tag } from "antd";

import Example from "./Example";

import "./Styles/Method.css";
const { Panel } = Collapse;

class Method extends React.Component<any, any> {
  static porpTypes = {
    method: {
      args: PropTypes.array,
      short_desc: PropTypes.string,
      desc: PropTypes.string,
      example: PropTypes.any,
      name: PropTypes.string,
      type: PropTypes.string,
      level: PropTypes.string,
      return_type: PropTypes.string,
      return_desc: PropTypes.string
    }
  };

  replaceContent() {
    if (this.props.method.type === "report") {
      this.props.method.return_desc = this.props.method.return_desc.replace(
        new RegExp("\\n"),
        "<br/>"
      );
    }
  }
  render() {
    this.replaceContent();

    const getParam = () => {
      let result: string[] = [];
      if (this.props.method.args.length > 0) {
        this.props.method.args.map((item: { arg: string; default: any }) => {
          if (item.default) {
            item.default === true
              ? result.push(item.arg + " = True")
              : item.default === false
              ? result.push(item.arg + " = False")
              : result.push(item.arg + " = " + item.default);
          } else {
            result.push(item.arg);
          }
          return "";
        });
      }
      return result.join(", ");
    };

    const header = (
      <div className="tag-container">
        <Tag
          color={
            this.props.method.type === "report"
              ? "#108ee9"
              : this.props.method.type === "event"
              ? "#FF9224"
              : "#87d068"
          }
          className="method-tag"
        >
          {this.props.method.name + "(" + getParam() + ")"}
        </Tag>
        <b>{this.props.method.short_desc}</b>
        <b style={{ float: "right", padding: "1px 0 0 0" }}>
          Level - {this.props.method.level}
        </b>
      </div>
    );

    const returnEl = (
      <div className="return-desc-container">
        <div>
          <b>返回类型 : {this.props.method.return_type}</b>
        </div>
        <div
          className="return-desc"
          dangerouslySetInnerHTML={{ __html: this.props.method.return_desc }}
        ></div>
      </div>
    );

    return (
      <div className="method-container">
        <Collapse expandIconPosition={"right"}>
          <Panel
            header={header}
            key="1"
            className={this.props.method.type}
            id={this.props.method.name.replace(/_/g, "-")}
          >
            <div className="method-content">
              <div
                className="desc"
                dangerouslySetInnerHTML={{ __html: this.props.method.desc }}
              ></div>
              {this.props.method.type === "report" ? returnEl : ""}
              {this.props.method.args.length > 0 ? (
                <div
                  className="args-area"
                  style={{ display: "flex", padding: "1.5em 0 1em 1em" }}
                >
                  <div style={{ width: "auto" }}>
                    <b>参数 : </b>
                  </div>
                  <ul>
                    {this.props.method.args.map((item: any) => (
                      <li key={item.desc}>
                        {item.arg}({item.type}) - {item.desc}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ""
              )}
              <Example html={this.props.method.example}></Example>
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  }
}

export default Method;
