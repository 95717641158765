import React from "react";
import { Icon, message } from "antd";
import copy from "copy-to-clipboard";
import "./Styles/Method.css";
import "highlight.js/styles/github-gist.css";

const hljs = require("highlight.js");
hljs.registerLanguage("python", require("highlight.js/lib/languages/python"));

class Example extends React.Component<any, any> {
  node: any;

  constructor(props: any) {
    super(props);
    this.handleCopyClick = this.handleCopyClick.bind(this);
  }
  componentDidMount() {
    if (this.props.html) {
      hljs.highlightBlock(this.node);
    }
    // if (this.node) {
    //   const a = this.node.querySelector("a");
    //   if (a)
    //     a.addEventListener("click", (event: any) => {
    //       const targetId = event.target.innerText.replace(/_/g, "-");
    //       const target = document.querySelector("#" + targetId);
    //     });
    // }
  }

  handleCopyClick() {
    copy(this.node.innerText);
    message.success("复制成功", 2);
  }

  render() {
    if (this.props.html) {
      return (
        <div>
          <h2>
            Example :{" "}
            <Icon
              type="copy"
              title="复制这段代码"
              onClick={this.handleCopyClick}
            />
          </h2>
          <div className="code">
            <div className="hljs">
              <div className="python">
                <div
                  ref={node => (this.node = node)}
                  dangerouslySetInnerHTML={{
                    __html: this.props.html
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }
}

export default Example;
