import React from "react";
import axios from "axios";
import { Collapse, Checkbox, Row, Col, Button, Input, Select } from "antd";

import Method from "./Method";

import "antd/dist/antd.css";
import "./Styles/Doc.css";

const { Panel } = Collapse;
const { Search } = Input;
const { Option } = Select;
axios.defaults.baseURL = "https://api.test.wonderbits.cn";

const tagMap = [
  { name: "display", value: "显示" },
  { name: "rgb", value: "颜色" },
  { name: "buzzer", value: "蜂鸣器" },
  { name: "button", value: "按钮" },
  { name: "sensor", value: "感应" },
  { name: "radio", value: "射频" },
  { name: "ble", value: "蓝牙" },
  { name: "wifi", value: "WIFI" }
];
class Doc extends React.Component<any, any> {
  doc: any;
  searchInput: any;
  tags: string[];

  state = {
    levels: ["1", "2"],
    activeKey: ["1"],
    filteredMethod: [],
    filterTags: []
  };
  constructor(props: any) {
    super(props);
    let name =
      this.getUrlParam("name") === null ? "Pi" : this.getUrlParam("name");
    this.tags = [];
    this.onChange = this.onChange.bind(this);
    this.toggleChecked = this.toggleChecked.bind(this);
    this.toggleDoc = this.toggleDoc.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.concatMethods = this.concatMethods.bind(this);
    this.handleFilterTags = this.handleFilterTags.bind(this);
    axios
      .get("/doc/?name=" + name, {
        method: "GET"
      })
      .then(resonpose => {
        this.doc = resonpose.data;
        console.log(resonpose.data);
        this.collectTags();
        this.concatMethods();
      });
  }

  componentDidMount() {
    window.addEventListener(
      "message",
      function(event) {
        if (event.data.func === "requestFullScreen") {
          const iframe = window.document.querySelector(".iframe-screen") as any;
          if (iframe) {
            const target = document as any;
            var isFullScreen =
              target.fullscreenElement ||
              target.mozFullScreenElement ||
              target.webkitFullscreenElement;
            if (!isFullScreen) {
              if (iframe.requestFullscreen) {
                iframe.requestFullscreen();
              } else if (iframe.mozRequestFullScreen) {
                iframe.mozRequestFullScreen();
              } else if (iframe.msRequestFullscreen) {
                iframe.msRequestFullscreen();
              } else if (iframe.webkitRequestFullscreen) {
                iframe.webkitRequestFullScreen();
              }
            } else {
              if (target.exitFullscreen) {
                document.exitFullscreen();
              } else if (target.msExitFullscreen) {
                target.msExitFullscreen();
              } else if (target.mozCancelFullScreen) {
                target.mozCancelFullScreen();
              } else if (target.webkitExitFullscreen) {
                target.webkitExitFullscreen();
              }
            }
          }
        }
      },
      false
    );
  }

  getUrlParam(name: string) {
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    const result = window.location.search.substr(1).match(reg);
    if (result != null) return unescape(result[2]);
    return null;
  }

  concatMethods() {
    this.doc.event_methods.map((item: any) => (item.type = "event"));
    this.doc.allMethods = this.doc.methods.concat(this.doc.event_methods);
    this.setState({
      filteredMethod: this.doc.allMethods
    });
  }

  onChange(checkedValues: any) {
    this.setState({
      levels: checkedValues
    });
  }

  componentDidUpdate() {
    window.parent.postMessage(
      {
        func: "getHeight",
        message: document.body.scrollHeight
      },
      "*"
    );
    const searchIcon = document.querySelector(
      ".header-container .ant-input-suffix"
    );
    const event = (event: any) => {
      event.stopPropagation();
      const keyWorkds = this.searchInput.input.state.value;
      if (keyWorkds) {
        this.handleSearch(keyWorkds);
      }
    };
    if (searchIcon) {
      searchIcon.removeEventListener("click", event);
      searchIcon.addEventListener("click", event);
    }
  }

  collectTags() {
    this.doc.methods.forEach((method: any) => {
      if (method.tags) {
        method.tags.forEach((tag: string) => {
          if (tag.trim() !== "" && this.tags.indexOf(tag) === -1) {
            this.tags.push(tag);
          }
        });
      }
    });
  }

  toggleChecked(event: any) {
    event.stopPropagation();
    this.setState({
      levels: this.state.levels.length === 4 ? [] : ["1", "2", "3", "4"]
    });
  }

  getDisplayName(key: string) {
    const find = tagMap.find(tag => {
      return tag.name === key;
    });
    if (find) {
      return find.name;
    } else {
      return key;
    }
  }

  handleSearch(value: string) {
    let result = new Array<any>();
    this.doc.allMethods.forEach((item: any) => {
      if (
        item.name.indexOf(value) > -1 ||
        item.short_desc.indexOf(value) > -1
      ) {
        result.push(item);
      }
    });
    this.setState({
      filteredMethod: result
    });
  }

  handleFilterTags(value: string[]) {
    this.setState({ filterTags: value });
  }

  toggleDoc() {
    this.setState({
      activeKey: this.state.activeKey.length === 1 ? [] : ["1"]
    });
  }

  filiterMethod(methods: any[]) {
    const result: any[] = [];
    if (this.state.filterTags.length) {
      console.log(this.state.filterTags);
      methods.forEach(m => {
        this.state.filterTags.forEach(tag => {
          if (m.tags && m.tags.indexOf(tag) > -1) {
            result.push(m);
          }
        });
      });
      return result;
    } else {
      return this.state.filteredMethod;
    }
  }
  render() {
    if (this.doc) {
      const btn = (
        <Button
          type="primary"
          size="default"
          onClick={this.toggleChecked}
          style={{
            height: "50 %",
            position: "relative",
            top: "16px",
            right: "10px"
          }}
        >
          {this.state.levels.length === 4 ? "反选" : "全选"}
        </Button>
      );
      const header = (
        <div className="header-container" onClick={this.toggleDoc}>
          <div className="header-left">
            <h2>API</h2>
          </div>
          <Search
            placeholder="搜索"
            style={{ height: "35px", marginTop: "15px", width: "70%" }}
            onClick={event => {
              event.stopPropagation();
            }}
            onSearch={this.handleSearch}
            size="small"
            className="search"
            ref={node => {
              this.searchInput = node;
            }}
          ></Search>
          <div className="header-right">
            {btn}
            <Checkbox.Group
              style={{ width: "15em", lineHeight: "2.5em" }}
              onChange={this.onChange}
              defaultValue={["1", "2", "3", "4"]}
              value={this.state.levels}
            >
              <Row>
                <Col
                  span={10}
                  onClick={event => {
                    event.stopPropagation();
                  }}
                >
                  <Checkbox value="1">
                    <b>Level-1</b>
                  </Checkbox>
                </Col>
                <Col
                  span={10}
                  onClick={event => {
                    event.stopPropagation();
                  }}
                >
                  <Checkbox value="2">
                    <b>Level-2</b>
                  </Checkbox>
                </Col>
                <Col
                  span={10}
                  onClick={event => {
                    event.stopPropagation();
                  }}
                >
                  <Checkbox value="3">
                    <b>Level-3</b>
                  </Checkbox>
                </Col>
                <Col
                  span={10}
                  onClick={event => {
                    event.stopPropagation();
                  }}
                >
                  <Checkbox value="4">
                    <b>Level-4</b>
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>

            <div
              onClick={event => {
                event.stopPropagation();
              }}
              style={{
                marginRight: "15px"
              }}
            ></div>
          </div>
        </div>
      );
      const allMethods = this.filiterMethod(this.state.filteredMethod);
      return (
        <div className="doc-container">
          <Collapse
            expandIconPosition={"right"}
            defaultActiveKey={["1"]}
            activeKey={this.state.activeKey}
          >
            {/* <Panel header={header} key="1">
              {this.filiterMethod(
                this.state.filteredMethod
              ).map((item: any, index: number) =>
                this.state.levels.indexOf(item.level) > -1 ? (
                  <Method method={item} key={item.name + index}></Method>
                ) : (
                  ""
                )
              )}
            </Panel> */}
            <Panel header={header} key="1">
              <div>
                <Select
                  mode="multiple"
                  style={{
                    width: "100%",
                    minWidth: "200px",
                    margin: "15px 0px"
                  }}
                  placeholder="选择标签"
                  defaultValue={[]}
                  onChange={this.handleFilterTags}
                >
                  {this.tags.map((tag: string, index: number) => {
                    return <Option key={tag}>{tag}</Option>;
                  })}
                </Select>
                {this.state.filterTags.map(tag => {
                  return (
                    <div className="tag-panel">
                      <Collapse
                        expandIconPosition={"right"}
                        defaultActiveKey={this.state.filterTags}
                      >
                        <Panel
                          header={
                            <div style={{}}>{this.getDisplayName(tag)}</div>
                          }
                          key={tag}
                        >
                          {allMethods.map((m: any, index: any) => {
                            if (m.tags && m.tags.indexOf(tag) > -1) {
                              return (
                                <Method
                                  method={m}
                                  key={m.name + index}
                                ></Method>
                              );
                            } else {
                              return "";
                            }
                          })}
                        </Panel>
                      </Collapse>
                    </div>
                  );
                })}
                {this.state.filterTags.length === 0
                  ? this.tags.map((tag: any, index: any) => {
                      return (
                        <div className="tag-panel">
                          <Collapse
                            expandIconPosition={"right"}
                            defaultActiveKey={this.tags}
                          >
                            <Panel
                              header={
                                <div style={{}}>{this.getDisplayName(tag)}</div>
                              }
                              key={tag}
                            >
                              {allMethods.map((m: any, index: any) => {
                                if (m.tags && m.tags.indexOf(tag) > -1) {
                                  return (
                                    <Method
                                      method={m}
                                      key={m.name + index}
                                    ></Method>
                                  );
                                } else {
                                  return "";
                                }
                              })}
                            </Panel>
                          </Collapse>
                        </div>
                      );
                    })
                  : ""}
                {this.tags.length === 0 ? (
                  <div>
                    {allMethods.map((m: any, index: any) => {
                      return <Method method={m} key={m.name + index}></Method>;
                    })}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Panel>
          </Collapse>
          {/* <iframe src='http://localhost:3000/' style={{ width: '100%', height: '1000px' }} allowFullScreen title='mytest' className='iframe-screen'></iframe> */}
        </div>
      );
    } else {
      return "";
    }
  }
}
export default Doc;
